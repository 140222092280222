import React, {
    useCallback, useMemo,
} from 'react'
import AddIcon from '@mui/icons-material/Add'

import orderManagementRequest from 'app/Apps/OrderManagement/services/orderManagement.request'
import {
    TableControlTypes,
} from 'app/shared-components/TableControls'
import SkyNetTable from 'app/shared-components/SkyNetTable'
import Button from 'app/shared-components/Button'
import {
    LeaseType,
} from 'app/types/enums'

import useStyles from './Pooling.style'
import getDirectLeaseConfig from './configs/directLease.config'
import getInternalTransportsConfig from './configs/internalTransports.config'
import getContainerSelectionConfig from './configs/containerSelection.config'
import getAirlineLeaseConfig from './configs/airlineLease.config'
import getPickupAddInfoConfig from './configs/pickupAddInfo.config'
import getPickupAddPickUpConfig from './configs/pickupAddPickUp.config'
import getPickupDeliveryConfirmationConfig from './configs/pickupDeliveryConfirmation.config'
import getTransportDepartureConfig from './configs/transportDeparture.config'
import getTransportArrivalConfig from './configs/transportArrival.config'
import getDeliveryAddInfoConfig from './configs/deliveryAddInfo.config'
import getDeliveryAddPickupConfig from './configs/deliveryAddPickup.config'
import getDeliveryConfirmationConfig from './configs/deliveryConfirmation.config'
import getPreBookings from './configs/preBookings.config'
import useOrderManagementRoutes from '../hooks/useOrderManagementRoutes'

const tableControls: TableControlTypes[] = [
    TableControlTypes.ClearFilters,
    TableControlTypes.ClearSorting,
]

const Pooling = () => {
    const Routes = useOrderManagementRoutes()
    const {
        classes,
    } = useStyles()

    const openCreateForm = useCallback(
        ({
            collectionDropoffPointId, selectedPreviousOrderId,
        }): void => {
            Routes.InternalOrders.Create.navigate({
                options: {
                    state: {
                        collectionDropoffPointId,
                        selectedPreviousOrderId,
                    },
                },
            })
        },
        [Routes],
    )

    const onRowClick = useCallback(
        (
            name: string,
            {
                orderNumber,
            }: {
          orderNumber: string;
        },
        ): void => {
            if (name === 'customerTransports') {
                Routes.CustomerOrders.OrderSteps.navigate({
                    params: {
                        key: orderNumber,
                    },
                })
                return
            }
            if (name === 'internalTransports') {
                Routes.InternalOrders.OrderSteps.navigate({
                    params: {
                        key: orderNumber,
                    },
                })
                return
            }
            if (name === 'containerSelection') {
                Routes.InternalOrders.ContainerSelection.navigate({
                    params: {
                        key: orderNumber,
                    },
                })
            }
        },
        [Routes],
    )

    const onRowClickPreBookings = ({
        id,
    }: { id: number }): void => {
        Routes.PreBookings.Overview.navigate({
            params: {
                key: id,
            },
        })
    }

    const {
        containerSelectionConfig,
        preBookings,
        directLeaseConfig,
        airlineLeaseConfig,
        pickupAddInfoConfig,
        pickupAddPickUpConfig,
        pickupDeliveryConfirmationConfig,
        transportDepartureConfig,
        transportArrivalConfig,
        deliveryAddInfoConfig,
        deliveryAddPickupConfig,
        deliveryConfirmationConfig,
        internalTransportsConfig,
    } = useMemo(() => {
        return {
            containerSelectionConfig: getContainerSelectionConfig(),
            preBookings: getPreBookings(),
            directLeaseConfig: getDirectLeaseConfig(openCreateForm),
            airlineLeaseConfig: getAirlineLeaseConfig(openCreateForm),
            pickupAddInfoConfig: getPickupAddInfoConfig(),
            pickupAddPickUpConfig: getPickupAddPickUpConfig(),
            pickupDeliveryConfirmationConfig: getPickupDeliveryConfirmationConfig(),
            transportDepartureConfig: getTransportDepartureConfig(),
            transportArrivalConfig: getTransportArrivalConfig(),
            deliveryAddInfoConfig: getDeliveryAddInfoConfig(),
            deliveryAddPickupConfig: getDeliveryAddPickupConfig(),
            deliveryConfirmationConfig: getDeliveryConfirmationConfig(),
            internalTransportsConfig: getInternalTransportsConfig(),
        }
    }, [openCreateForm])

    return (
        <div className={classes.root}>
            <div className={classes.headerTitle}>
                Container Selection
                <div className={classes.actionWrapper}>
                    <Button
                        label="Create Internal Order"
                        onClick={openCreateForm}
                        data-testid="openCreateFormBtn"
                        icon={<AddIcon />}
                    />
                </div>
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingContainerSelection}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('containerSelection', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingContainerSelection
                    }
                    tableConfig={containerSelectionConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.headerTitle}>Pre Bookings</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.PreBookingsTransports}
                    tableControls={tableControls}
                    onRowClick={onRowClickPreBookings}
                    customUrl={orderManagementRequest.Pooling.url.preBookings}
                    tableConfig={preBookings}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                    data-testid="preBookingsTransports"
                />
            </div>
            <div className={classes.headerTitle}>Customer Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('customerTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingCustomerTransport
                    }
                    filter={{
                        orderSubType: [LeaseType.DIRECT_LEASE],
                    }}
                    tableConfig={directLeaseConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingCustomerTransport}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('customerTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingCustomerTransport
                    }
                    filter={{
                        orderSubType: [LeaseType.AIRLINE_LEASE],
                    }}
                    tableConfig={airlineLeaseConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>Internal Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddInfo}
                    tableConfig={pickupAddInfoConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.poolingPickupAddPickup}
                    tableConfig={pickupAddPickUpConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.pickupDeliveryConfirmation
                    }
                    tableConfig={pickupDeliveryConfirmationConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportDeparture}
                    tableConfig={transportDepartureConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.transportArrival}
                    tableConfig={transportArrivalConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddInfo}
                    tableConfig={deliveryAddInfoConfig}
                    isShowCounterTab
                    showSearch
                    paginationModeMatches
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryAddPickup}
                    tableConfig={deliveryAddPickupConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={orderManagementRequest.Pooling.url.deliveryConfirmation}
                    tableConfig={deliveryConfirmationConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
            <div className={classes.headerTitle}>Open Transports</div>
            <div className={classes.tableWrapper}>
                <SkyNetTable
                    name={orderManagementRequest.Pooling.name.poolingInternalTransports}
                    tableControls={tableControls}
                    onRowClick={(args) => {
                        onRowClick('internalTransports', args)
                    }}
                    uniqField="orderNumber"
                    customUrl={
                        orderManagementRequest.Pooling.url.poolingInternalTransports
                    }
                    tableConfig={internalTransportsConfig}
                    isShowCounterTab
                    paginationModeMatches
                    showSearch
                />
            </div>
        </div>
    )
}

export default Pooling
